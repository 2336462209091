import { Drawer, Menu } from "antd";
import { WithRouterProps } from "next/dist/client/with-router";
import Link from "next/link";
import { withRouter } from "next/router";
import { ModalsComponentProps } from "~/components/Modals";
import { links } from "~/components/nav";
import styles from "./style.module.scss";

interface Props extends ModalsComponentProps, WithRouterProps {}

const MobileNavs = (props: Props) => {
  return (
    <Drawer
      placement="right"
      visible={props.visible}
      destroyOnClose={true}
      onClose={() => props.onCancel()}
      width={160}
      closable={false}
      className={styles["mobile-navs"]}
    >
      <Menu
        mode="vertical"
        defaultSelectedKeys={[props.router.pathname]}
        onSelect={props.onOk}
      >
        {links.map(({ href, label, target }) => (
          <Menu.Item key={href}>
            <Link passHref href={href} target={target}>
              {label}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </Drawer>
  );
};

export default withRouter(MobileNavs);
