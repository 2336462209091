import "../common/style/vars.css";
import "../common/style/reset.scss";
import App from "next/app";
import Layout from "../components/layout";
// import { initializeStore } from "../stores";
// import { Provider, observer } from "mobx-react";
import { ConfigProvider, FloatButton } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import Head from "next/head";
import { SITE_TITLE, getHost } from "~/utils";
import Modals from "~/components/Modals";
import Theme from "./theme/themeConfig";

class MyApp extends App {
  // mobxStore = {};
  static async getInitialProps(appContext) {
    // const ctx = appContext.ctx;
    // ctx.mobxStore = initializeStore();
    const appProps = await App.getInitialProps(appContext);

    return {
      ...appProps,
      // initialMobxState: ctx.mobxStore,
    };
  }

  constructor(props) {
    super(props);
    // const isServer = typeof window === "undefined";
    // this.mobxStore = isServer
    //   ? props.initialMobxState
    //   : initializeStore(props.initialMobxState);
  }

  componentDidMount() {
    this.initAds();
  }

  initAds = () => {
    if (!window.adsbygoogle) {
      window.adsbygoogle = [];
    }
    if (!window.adsbygoogle.length) {
      window.adsbygoogle.push({});
    }
  };

  renderContent = () => {
    const { Component, pageProps } = this.props;

    return (
      <Layout>
        <Head>
          <title key="title">{SITE_TITLE}</title>
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,user-scalable=no,maximum-scale=1.0,viewport-fit=cover"
          />
          <link
            rel="canonical"
            href={getHost() + (this.props?.router?.asPath || "")}
          ></link>
        </Head>
        <Component {...pageProps} />
      </Layout>
    );
  };

  render() {
    return (
      // <Provider {...this.mobxStore}>
        <ConfigProvider locale={zhCN} theme={Theme}>
          {this.renderContent()}
          <Modals />
          <FloatButton.BackTop />
        </ConfigProvider>
      // </Provider>
    );
  }
}

export default MyApp;
