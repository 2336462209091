import React from "react";
import Navs from "../../nav";
import Style from "./style.module.scss";
import { MenuOutlined } from "@ant-design/icons";
import Modals from "~/components/Modals";
import MobileNavs from "../mobileNavs";
import { SITE_NAME } from "~/utils";

const Header = () => {
  const handleMobileNavs = () => {
    Modals.show(MobileNavs);
  };

  return (
    <div className={Style.header}>
      <nav className={Style.header_left}>
        <a className={Style["logo-container"]} href="/">
          <img alt="logo" src="/images/logo-48.png" />
          <h3>{SITE_NAME}</h3>
        </a>

        <Navs />
      </nav>
      <div className={Style.header_right}>

        <MenuOutlined
          className={Style["menu-icon"]}
          onClick={handleMobileNavs}
        />
      </div>
    </div>
  );
};

export default Header;
