import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { withRouter } from "next/router";
import Link from "next/link";
import Style from "./style.module.scss";

export const links = [
  { href: "/", label: "首页", id: "home" },
  { href: "/sites", label: "精选站点", id: "sites" },
  { href: "/about", label: "关于", id: "about" },
  {
    href: "/advice",
    label: "问题反馈",
    id: "advice",
    href: "https://support.qq.com/product/633113",
    target: "_blank",
  },
];

function Nav({ router }) {
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    if (router.pathname === "/sites/[page]") {
      setSelectedKeys(["/sites"])
    } else {
      setSelectedKeys([router.pathname])
    }
  }, [router.pathname]);

  return (
    <Menu
      mode="horizontal"
      // defaultSelectedKeys={[router.pathname]}
      selectedKeys={selectedKeys}
      className={Style.navs}
    >
      {links.map(({ href, label, target }) => (
        <Menu.Item key={href} className={Style["nav-item"]} value={target}>
          <Link passHref href={href} target={target}>
            {label}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default withRouter(Nav);
