import * as React from "react";
import { v4 as uuidv4 } from "uuid";

export interface ModalsComponentProps {
  onOk?: (...data: any) => void;
  onCancel?: (...data: any) => void;
  visible?: boolean;
  open?: boolean;
}

export interface IModals extends ModalsComponentProps{
  [key:string]: any;
}

interface ModalsState {
  modals: Array<{
    id: string;
    Comp: React.ComponentType<any> | React.FC<any> | React.ReactNode; // 只要提供了对应的props都可以显示，不仅仅是Modal组件
    props: {
      onOk: (data?: any) => void;
      onCancel: (data?: any) => void;
      visible: boolean;
      open: boolean;
      [key:string]: any;
    } & IModals;
  }>;
}

export default class Modals extends React.Component<any, ModalsState> {
  static install: Modals;

  constructor(props: any) {
    super(props);
    if (Modals.install) {
      return Modals.install;
    }
    Modals.install = this;

    this.state = {
      modals: [],
    };
  }

  public static show = (
    component: React.ComponentType<any> | React.FC<any> | React.ReactNode,
    props: IModals = {}
  ) => {
    const { modals = [] } = Modals.install.state;
    return new Promise((resolve, reject) => {
      const id = uuidv4();
      modals.push({
        id,
        Comp: component,
        props: {
          ...props,
          visible: true,
          open: true,
          onOk: (...data) => {
            props.onOk && props.onOk(data);
            Modals.install.onClose(id);
          },
          onCancel: (...data) => {
            props.onCancel && props.onCancel(data);
            Modals.install.onClose(id);
          },
        },
      });
      Modals.install.setState({});
    });
  };

  public static clear() {
    if (Modals.install) {
      Modals.install.state.modals.forEach(modal => {
        Modals.install.onClose(modal.id);
      })
    }
  }

  /**
   * @param id 每次关闭弹窗直接将组件移除，避免组件的状态在下次进入时影响组件的逻辑
   */
  private onClose = (id: string) => {
    const { modals = [] } = this.state;
    this.setState({
      modals: modals.filter((modal) => modal.id !== id),
    });
  };

  render() {
    const { modals = [] } = this.state;
    return (
      <div>
        {modals.map((item) => {
          const Comp: any = item.Comp;
          return <Comp key={item.id} {...item.props} />;
        })}
      </div>
    );
  }
}
